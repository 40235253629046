var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "download_app_page un_sel" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", [
        _vm._v("如未自动下载,请点击\n      "),
        _c(
          "span",
          {
            staticClass: "click_download",
            on: {
              click: function ($event) {
                return _vm.clickDownload()
              },
            },
          },
          [_vm._v("手动下载")]
        ),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.is_wechat && !_vm.is_ios,
            expression: "is_wechat && !is_ios",
          },
        ],
        staticClass: "wechat_group",
      },
      [
        _c("img", {
          staticClass: "arrow",
          attrs: {
            src: require("@/assets/images/download_app/arrow.png"),
            alt: "",
          },
        }),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hint_group" }, [
      _c("div", { staticClass: "text" }, [
        _c("span", [_vm._v("选择在浏览器")]),
        _c("div", { staticClass: "browser_group" }, [
          _c("img", {
            staticClass: "browser",
            attrs: {
              src: require("@/assets/images/download_app/browser.png"),
              alt: "",
            },
          }),
        ]),
        _c("span", [_vm._v("打开即可正常下载")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }