<template>
  <div class="download_app_page un_sel">
    <div class="container">
      <div>如未自动下载,请点击
        <span class="click_download"
          @click="clickDownload()"
        >手动下载</span>
      </div>
    </div>
    <div class="wechat_group"
      v-show="is_wechat && !is_ios"
    >
      <img src="~@/assets/images/download_app/arrow.png" alt="" class="arrow">
      <div class="hint_group">

        <div class="text">
          <span>选择在浏览器</span>
          <div class="browser_group">
            <img src="~@/assets/images/download_app/browser.png" alt="" class="browser">
          </div>
          <span>打开即可正常下载</span>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  data(){
    return{
      is_wechat: false,
      double_click: false,
      is_ios: false,
    }
  },
  methods: {
    isWechat(){
      let is_wechat = false;
      let wx = navigator.userAgent.toLowerCase()
      if (wx.match(/MicroMessenger/i) == 'micromessenger') {
        is_wechat = true
      } else {
        is_wechat = false
      }
      this.is_wechat = is_wechat;
      return is_wechat;
    },
    isIos(){
      var u = navigator.userAgent,
      isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if(isiOS){
        this.is_ios = true;
        return true;
      }
      return false;
    },
    async getUrl(){
      var u = navigator.userAgent,
      isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
      isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

      let res, url;
      try{
        res = await this.$http.request('projectCommon/download');
        if(res.message == 'success'){
          const {downloadAppAndroid,downloadAppIOS} = res.contents;
          if(isAndroid){
            url = downloadAppAndroid;
            this.download(url);
          }
          if(isiOS){
            url = downloadAppIOS;
            // url.replace('https://', 'itms-appss:');
            window.location.href=url;

          }
        }
      }catch(err){
        // console.log(err);
      }
      return url;
    },

    download(url){
       // iframe下载
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // 防止影响页面
      iframe.style.height = 0; // 防止影响页面
      iframe.src = url;
      document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
      setTimeout(()=>{
        iframe.remove();
      }, 5 * 60 * 1000);
    },
    async doDownload(){
      let url = await this.getUrl();
    },
    clickDownload(){
      const {double_click} = this;
      if(double_click === false){
        this.double_click = true;
        this.doDownload();
        setTimeout(()=>{
          this.double_click = false;
        },2000)
      }
    }
  },
  async mounted(){
    let is_wechat = this.isWechat();
    let is_ios = this.isIos();
    if(!is_wechat || is_ios){
      this.doDownload();
    }
  },
}
</script>

<style lang="scss" scoped>
.download_app_page{
  @include flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.click_download{
  text-decoration: underline;
  color: blue;
}
.wechat_group{
  @include flex;
  @include bbox;
  padding: 0 10px;
  z-index: 3;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  flex-direction: column;
  align-items: flex-end;
  .arrow{
    width: 80px;
  }
  .hint_group{
    // @include flex;
    padding-top: 20px;
    .text{
      color: #fff;
      padding: 0 10px;
      font-size: 15px;
      span{
        display: inline-block;
        vertical-align: middle;
      }
      .browser_group{
        text-align: center;
        width: 35px;
        height: 31px;
        display: inline-block;
        vertical-align: middle;
        border:1px solid #fff;
        border-radius: 4px;
        line-height: 30px;
        background-color: rgba(0,0,0,0.3);
        margin: 0 4px;
      }
      .browser{
        width: 21px;
        height: 21px;
        margin-top:4px;
      }
    }
  }
}
</style>
